<template>
   <h2 class="subBanner"><img src="@/assets/img/icon_gdia.svg">{{$t('front.common.qna')}}</h2>
   <div class="w240 navSide">
     <ul class="boardnav">
      <li>
        <span @click="goPageByName('qnaList')" class="csmenu111">
          <em></em>문의내역
        </span>
      </li>
      <li class="on">
        <span @click="goPageByName('qnaWrite')" class="csmenu112">
          <em></em>문의작성
        </span>
      </li>
     </ul>
   </div>
  <div class="w1004 writeWrap">
    <div class="board">
<!--      <ul class="boardwrt">-->
<!--        <li class="w160">유형</li>-->
<!--        <li class="w100w">-->
<!--          <select v-model="model.category">-->
<!--            <template v-for="category in categoryList" v-bind:key="category">-->
<!--              <option :value="category">{{$t('front.gameCategory.' + category)}}</option>-->
<!--            </template>-->
<!--          </select>-->
<!--        </li>-->
<!--      </ul>-->
      <ul class= "boardwrt">
        <li>{{$t('front.board.type')}}</li>
        <li class="w100w">
          <select v-model="model.type">
            <template v-for="type in typeList" v-bind:key="type">
              <option :value="type">{{$t('front.qnaTypeCategory.' + type)}}</option>
            </template>
          </select>
        </li>
      </ul>
      <ul class="boardwrt">
        <li>{{$t('front.board.title')}}</li>
        <li class="w100w"><input type="" :placeholder="$t('front.board.emptyTitle')" v-model="model.title"/></li>
      </ul>
      <ul class="boardwrt">
        <li>{{$t('front.search.content')}}</li>
        <li class="w100w"><textarea
          :placeholder="$t('front.board.commentPlaceholder')" v-model="model.content"></textarea></li>
      </ul>
    </div>

    <div class="boardbtn mt20 applybtn">
      <ui-button :className="'grbbtn'" :text="$t('front.board.cancel')" @click="onClickCancel"/>
      <ui-button :className="'grsbtn'" :text="$t('front.board.apply')" @click="onClickSubmit"/>
    </div>

  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'qnaWrite',
  components: {
    UiButton
  },
  data () {
    return {
      categoryList: [
        'livecasino',
        'slot',
        'sports',
        'esports',
        'minigame',
        'virtualgame',
        'cash',
        'betting',
        'etc'
      ],
      typeList: [
        'bankaccount',
        'etc'
      ],
      model: {
        category: '',
        type: 'etc',
        title: '',
        content: ''
      }
    }
  },
  created () {
  },
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    onClickCancel () {
      this.$router.go(-1)
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    },
    onClickSubmit () {
      if (!this.model.title) {
        this.onAlert('warningart', 'front.board.emptyTitle')
        return false
      }

      if (!this.model.content) {
        this.onAlert('warningart', 'front.board.emptyContent')
        return false
      }

      const params = {
        title: this.model.title,
        content: this.model.content,
        boardType: 'faq',
        category: this.model.category,
        faqType: this.model.type
      }
      if (this.boardIdx) {
        params.boardIdx = this.boardIdx
      }
      this.setSaveBoard(params)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
